import React from "react";
import {
  FaBatteryFull,
  FaBluetooth,
  FaCogs,
  FaWeightHanging,
  FaThermometerHalf,
} from "react-icons/fa";
import image from "../../images/2.webp";

const TechSpecifications = () => {
  return (
    <>
      <section className="bg-gray-800 text-white p-8 lg:p-16">
        <h2 className="text-2xl lg:text-3xl font-bold mb-8 text-center">
          TECH SPECIFICATIONS
        </h2>

        <div className="max-w-[1400px] w-full mx-auto grid gap-12 md:grid-cols-2 lg:grid-cols-3">
          {/* Quality Materials */}
          <div className="flex items-start space-x-4">
            <FaCogs className="text-3xl text-gray-300" />
            <div>
              <h3 className="text-xl font-semibold mb-2">QUALITY MATERIALS</h3>
              <ul className="text-sm text-gray-300 space-y-1">
                <li>
                  Non-allergenic titanium on inner and outer medringer surfaces
                </li>
                <li>Seamless titanium interior</li>
                <li>Minor scratches from regular wear are normal over time</li>
                <li>Water resistant up to 100m/328 ft</li>
              </ul>
            </div>
          </div>

          {/* Sensors */}
          <div className="flex items-start space-x-4">
            <FaThermometerHalf className="text-3xl text-gray-300" />
            <div>
              <h3 className="text-xl font-semibold mb-2">SENSORS</h3>
              <ul className="text-sm text-gray-300 space-y-1">
                <li>
                  Red and infrared LEDs measure blood oxygen levels while you
                  sleep
                </li>
                <li>
                  Green and infrared LEDs alternate to measure heart rate and
                  HRV 24/7
                </li>
                <li>Digital sensor measures temperature variations</li>
                <li>Accelerometer tracks movement and activity 24/7</li>
              </ul>
            </div>
          </div>

          {/* Battery Life */}
          <div className="flex items-start space-x-4">
            <FaBatteryFull className="text-3xl text-gray-300" />
            <div>
              <h3 className="text-xl font-semibold mb-2">BATTERY LIFE</h3>
              <ul className="text-sm text-gray-300 space-y-1">
                <li>Up to 8 days of battery*</li>
                <li>
                  Typically charges in 20-80 minutes, depending on battery level
                </li>
              </ul>
            </div>
          </div>

          {/* Weight and Dimensions */}
          <div className="flex items-start space-x-4">
            <FaWeightHanging className="text-3xl text-gray-300" />
            <div>
              <h3 className="text-xl font-semibold mb-2">
                WEIGHT AND DIMENSIONS
              </h3>
              <ul className="text-sm text-gray-300 space-y-1">
                <li>Width: 7.90mm</li>
                <li>Thickness: 2.88mm (similar to a wedding band)</li>
                <li>Weight: 3.3 to 5.2 grams</li>
              </ul>
            </div>
          </div>

          {/* Compatibility */}
          <div className="flex items-start space-x-4">
            <FaCogs className="text-3xl text-gray-300" />
            <div>
              <h3 className="text-xl font-semibold mb-2">COMPATIBILITY</h3>
              <ul className="text-sm text-gray-300 space-y-1">
                <li>App available in multiple languages</li>
                <li>Supports metric and imperial units</li>
                <li>Integrates with 40+ apps</li>
                <li>Available on iOS and Android</li>
              </ul>
            </div>
          </div>

          {/* Connectivity */}
          <div className="flex items-start space-x-4">
            <FaBluetooth className="text-3xl text-gray-300" />
            <div>
              <h3 className="text-xl font-semibold mb-2">CONNECTIVITY</h3>
              <ul className="text-sm text-gray-300 space-y-1">
                <li>Bluetooth® Low Energy</li>
                <li>Automatic firmware updates via App</li>
                <li>EMF-safe, allows airplane mode</li>
                <li>FCC-certified</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* // ------------------  */}
      {/* <section className="bg-gray-800 text-white p-10 sm:p-20 lg:p-16">
        <div className="max-w-[1440px] w-full mx-auto grid gap-12 lg:grid-cols-2">
          Quality Materials
          <div className="flex items-center text-left space-x-4">
            <FaCogs className="text-3xl text-gray-300" />
            <div className=" flex flex-col gap-3">
              <h5 className="text-[16px] font-bold leading-[20px] font-akkurat mb-2">What's included</h5>
              <h3 className="text-[56px] font-[300] leading-[56px] font-akkurat mb-2 lg:pr-40">
               MedRinger 4 Charger
              </h3>
              <p className="text-[18px] font-[400] leading-[24px] font-akkurat lg:pr-40">
                Every MedRinger comes with one size-specific charger and USB-C
                cable.
              </p>
            </div>
          </div>

          Sensors
          <div className="flex items-center space-x-4">
            <img src={image} alt="" />
          </div>
        </div>
      </section> */}

      <section className="bg-gray-800 text-white py-5">
        <div className="w-full py-2">
          <p className="w-[65%] text-[14px] font-[400] leading-[21px] font-akkurat mx-auto border-y border-[#838280] py-6">
          All claims regarding the MedRinger’s performance depend on various factors such as MedRinger App settings, feature configurations, usage patterns, age of the device, activity levels, and other circumstances. Actual performance results may vary based on these factors. Tests were conducted across different sizes and scenarios. <a href="" className="underline">See MedRinger performance tips for more information.</a>
          </p>
        </div>
      </section>
    </>
  );
};

export default TechSpecifications;
