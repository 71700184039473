import React, { useState } from "react";
import Header from "../components/Header";
import banner from "../images/orignal/webp/Hafiz-1.webp";
import { useForm, ValidationError } from "@formspree/react";

import { MdMail, MdPhone, MdLocationOn } from "react-icons/md";
export default function Contact() {
  //     const [formData, setFormData] = useState({
  //         fname: '',
  //         lname: '',
  //         email: '',
  //         phone: '',
  //         message: '',

  //     });

  //     const [isSubmitted, setIsSubmitted] = useState(false);

  //   const handleChange = (e) => {
  //     const { name, value } = e.target;
  //     setFormData({ ...formData, [name]: value });
  //   };

  //   const handleSubmit = (e) => {
  //     e.preventDefault();

  //     // Send form data to Formspree
  //     fetch("https://formspree.io/f/mpwzjewn", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(formData),
  //     })
  //       .then((response) => {
  //         if (response.ok) {
  //           setIsSubmitted(true);
  //           setFormData({ name: "", email: "", message: "" });
  //         } else {
  //           alert("There was an error submitting the form.");
  //         }
  //       })
  //       .catch(() => alert("An error occurred. Please try again."));
  //   };

  const [state, handleSubmit] = useForm("mpwzjewn");

  return (
    <>
      <Header
        backgroundImage={banner}
        bannerHeading="Contact Us"
        bannerText="Need help? Contact us, we’re here to assist you anytime."
      />

      <div className=" max-w-[1440px] w-full mx-auto font-akkurat py-5">
        {/* Top Map Section */}
        <div className="w-full h-[300px] mb-5">
          {/* Embed Google Map iframe for Virginia, Dale City, USA */}
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30909.081964003256!2d-77.2677111113291!3d38.62531323271267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b63bc0ca5a4bdb%3A0x25f5b22fffe50e0!2sDale%20City%2C%20VA%2C%20USA!5e0!3m2!1sen!2sin!4v1607347581421!5m2!1sen!2sin"
            width="100%"
            height="100%"
            frameBorder="0"
            allowFullScreen
            aria-hidden="false"
            tabIndex="0"
            title="Google Map"
          ></iframe>
        </div>

        {/* Main Content Section */}

        <div className="flex flex-col md:flex-row  max-w-[1440px] w-full mx-auto items-center justify-between py-10 mt-0 md:mt-5">
          <div className="w-[40%] space-y-10">
            <div className="flex flex-col items-center gap-3">
              <div className="flex w-12 h-12 hover:bg-gray-300 items-center justify-center rounded-full bg-gray-200">
                <MdMail className="h-8 w-8 text-[#fc0829]" />
              </div>
              <div className="text-xl font-[400] text-[#fc0829] text-muted-foreground">
                Email
              </div>
              <a
                href="mailto:info@medringer.com"
                className="text-lg text-center"
              >
                Info@medringer.com
              </a>
            </div>

            <div className="flex flex-col items-center gap-3">
              <div className="flex w-12 h-12 hover:bg-gray-300 items-center justify-center rounded-full bg-gray-200">
                <MdPhone className="h-8 w-8 text-[#fc0829]" />
              </div>
              <div className="text-xl font-[400] text-[#fc0829] text-muted-foreground">
                Phone
              </div>
              <a href="tel:+18443444666" className="text-lg text-center">
                +1-844-344-4666
              </a>
            </div>

            <div className="flex flex-col items-center gap-3">
              <div className="flex w-12 h-12 hover:bg-gray-300 items-center justify-center rounded-full bg-gray-200">
                <MdLocationOn className="h-8 w-8 text-[#fc0829]" />
              </div>
              <div className="text-xl font-[400] text-[#fc0829] text-muted-foreground">
                ADDRESS
              </div>
              <div className="text-lg text-center">
                Virginia, Dale City, USA
              </div>
            </div>
          </div>

          {/* Contact Form */}
          <div className="md:w-[60%] w-full p-6 space-y-6">
            <h2 className="text-3xl font-bold tracking-tight">
              LEAVE A <span className="text-[#fc0829]">MESSAGE</span>
            </h2>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid gap-4 sm:grid-cols-2">
                <input
                  placeholder="First Name*"
                  name="fname"
                  //   value={formData.fname}
                  //   onChange={handleChange}
                  required
                  type="text"
                  className="border border-slate-500 px-2 py-2 bg-gray-100 focus:bg-gray-100 focus:outline-none focus:border-[#fc0829]"
                />

                <input
                  placeholder="Last Name*"
                  name="lname"
                  //   value={formData.lname}
                  //   onChange={handleChange}
                  required
                  type="text"
                  className="border border-slate-500 px-2 py-2 bg-gray-100 focus:bg-gray-100 focus:outline-none focus:border-[#fc0829]"
                />
              </div>

              <div className="grid gap-4 sm:grid-cols-2">
                <input
                  placeholder="Email*"
                  name="email"
                  //   value={formData.email}
                  //   onChange={handleChange}
                  required
                  type="email"
                  className="border border-slate-500 px-2 py-2 bg-gray-100 focus:bg-gray-100 focus:outline-none focus:border-[#fc0829]"
                />
                <input
                  placeholder="Phone*"
                  name="phone"
                  //   value={formData.phone}
                  //   onChange={handleChange}
                  required
                  type="tel"
                  className="border border-slate-500 px-2 py-2 bg-gray-100 focus:bg-gray-100 focus:outline-none focus:border-[#fc0829]"
                />
              </div>

              <div className="grid">
                <textarea
                  placeholder="Message"
                  name="message"
                  //   value={formData.message}
                  //   onChange={handleChange}
                  className="min-h-[150px] border border-slate-500 px-2 py-2 bg-gray-100 focus:bg-gray-100 focus:outline-none focus:border-[#fc0829]"
                />
              </div>

              <div className="space-y-4">
                <div
                  className="g-recaptcha"
                  data-sitekey="your-recaptcha-site-key"
                ></div>
                <button
                  type="submit"
                  className="px-10 py-2 rounded-full text-white bg-[#EE344E] hover:bg-[#fc0829]"
                >
                  Send Message
                </button>
                {state.succeeded ? <p>Thanks for joining!</p> : null}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
