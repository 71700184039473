import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

// Array of images, related text, and unique colors for each dot
const sliderData = [
  { img: '/cerosal-images/3.webp', text: 'Ringer Watch', color: 'bg-[#DDCBA4]', link: '/category/ringer-watch' },
  { img: '/watch/2.webp', text: 'Push Button', color: 'bg-[#414141]', link: '/category/ringer-push-button' },
  { img: '/pendant/vip-active-main-3a.webp', text: 'Pendant', color: 'bg-[#C7C7C7]', link: '/category/pendant' },
  { img: '/Home/pdp-home2-gallery-01.webp', text: 'At Home', color: 'bg-[#C2C2C2]', link: '/category/cellular-home' },
  { img: '/lindline/pdp-classic-gallery-00.webp', text: 'Lindline', color: 'bg-[#767676]', link: '/category/landline' },
];


export default function ImageSlider() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false); // State to control the animation

  // Function to change slide based on the dot clicked
  const handleDotClick = (index) => {
    if (!isAnimating) {
      setIsAnimating(true);
      setActiveIndex(index);
      setTimeout(() => {
        setIsAnimating(false); // Reset the animation state after transition
      }, 500); // Match this duration with the CSS transition duration
    }
  };

  return (
    <>
      <div className="flex flex-col items-center max-w-[1440px] w-full mx-auto px-4 py-6 lg:px-0">
      {/* Slider Container */}
      <div className="relative md:w-[90%] w-[100%] overflow-hidden rounded-lg shadow-lg">
        <Link to={sliderData[activeIndex].link}>
          <img
            src={sliderData[activeIndex].img}
            alt={`Slide ${activeIndex + 1}`}
            className={`w-full bigdesktop:h-[700px] desktop:h-[700px] minitab:h-[400px] h-[300px] object-cover transition-opacity duration-500 ${
              isAnimating ? 'opacity-0' : 'opacity-100'
            }`}
          />
        </Link>

        {/* Dots Container */}
        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex items-center space-x-2 px-6 py-3 rounded-full bg-white">
          {sliderData.map((slide, index) => (
            <div key={index} className="flex items-center">
              <button
                onClick={() => handleDotClick(index)}
                className={`h-6 w-6 md:h-7 md:w-7 flex items-center justify-center rounded-full transition-transform duration-300 ${
                  activeIndex === index ? 'border border-black scale-110' : ''
                }`}
              >
                <div
                  className={`h-4 w-4 md:h-5 md:w-5 rounded-full ${slide.color}`} // Inner dot with color
                ></div>
              </button>

              {/* Related Text beside Active Dot */}
              {activeIndex === index && (
                <span className="ml-1 md:ml-2 text-grey-300 bg-opacity-70 px-2 py-1 rounded-md text-[10px] md:text-sm font-poppins">
                  {slide.text}
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>


      {/* -----------------------------  */}
      <motion.section className="flex flex-col desktop:flex-row desktop:gap-0 gap-3 max-w-[1440px] w-full mx-auto desktop:min-h-[20vh] h-auto justify-between bg-[#F5F5F7] px-6 py-12 lg:px-16 font-akkurat"
        initial={{ opacity: 0, y: 40 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
        viewport={{ amount: 0.2 }}
      >
        {/* Left Side Heading */}
        <div className="max-w-xl space-y-4 md:pr-28">
          <h2 className="desktop:text-[40px] text-4xl font-[400] leading-[44px] tracking-tight text-gray-700 bigdesktop:text-5xl">
            Medium Ringer Collection – Style Meets Comfort
          </h2>
          {/* Uncomment this if you want to add more content */}
          {/* <p className="text-lg text-gray-600">
      Discover the unique benefits of our product and how it can help you improve your lifestyle.
    </p> */}
        </div>
        {/* Empty Space (20% width) */}
        {/* Right Side Paragraph */}
        <div className="flex flex-col justify-start p-0 desktop:pl-8 w-[90%] md:w-[60%]" >
          <p className='text-gray-600 text-[18px] font-[400] p-0 desktop:pl-20'>
            Our Medium Ringer products blend timeless design with everyday comfort. Perfect for all occasions, each piece is made with high-quality materials and a perfect fit to keep you stylish and at ease throughout the day.

            <br />
            <br />

            Get yours today and experience the next level of casual wear—whether you're at home, out with friends, or on the go.


          </p>
        </div>


      </motion.section>

    </>
  );
}
