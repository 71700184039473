import React from 'react';
import { motion } from 'framer-motion';
import  image  from "../../images/1.webp";
import { Link } from 'react-router-dom';

const ResponsiveSection = () => {
  return (
    <div className=" max-w-[1360px] w-[100%] mx-auto">
    <motion.section className="flex mb-10 flex-col-reverse lg:flex-row items-center lg:h-auto h-auto bg-[#F5F5F7] p-6 lg:p-12 pl-3 text-[#4a4741] font-akkurat"
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.3 }}
    viewport={{ amount: 0.2 }}
    >
      {/* Left Column */}
      <div className="lg:w-[45%] w-full mb-8 lg:mb-0 text-center lg:text-left lg:pr-40 font-akkurat">
        <h1 className="text-2xl lg:text-[40px] font-[400] leading-[44px] font-akkurat mb-4">Our most advanced smart Medringer yet</h1>
        <p className="text-[20px] font-[400] leading-[24px] font-akkurat mb-6">
        See how it compares with MedRinger Gen3.
        </p>
        <Link to='/products' className="px-6 py-3 text-[16px] font-[400] leading-[24px] font-akkurat bg-[#EE344E] text-white rounded-3xl hover:bg-[#fc0829]">
        Compare MedRinger Models
        </Link>
      </div>
      {/* Right Column */}
      <div className="lg:w-[60%] w-full h-[300px]">
        <img
          src={image}
          alt="Sample"
          className="w-full h-full object-cover rounded-lg"
        />
      </div>
    </motion.section>
    </div>
  );
};

export default ResponsiveSection;
