"use client";

import * as React from "react";
import { useState } from "react";
import Vedio from "../../images/vedio/1st Pa rt Render.mp4";
import Vedio1 from "../../images/vedio/Direct Render 2nd View.mp4";
import { motion } from "framer-motion";
import ReactPlayer from 'react-player';
import { IoPlayCircleOutline } from "react-icons/io5";

export default function SectionWithTransitions() {
  // State to manage active tab
  const [activeTab, setActiveTab] = useState(0);

  // Tab content
  const tabs1 = [
    {
      title: "Increases accuracy",
      description:
        "Our devices provide continuous, precise data for a comprehensive understanding of your health",
    },
    {
      title: "Adapts to you",
      description:
        "Intelligently responds to your needs, offering customizable features to fit your lifestyle.",
    },
    {
      title: "Optimizes battery",
      description:
        "Maximizes energy efficiency, ensuring long-lasting performance with every charge.",
    },
    
  ];

  const tabs2 = [
    {
      title: "Fully titanium",
      description: "Crafted from premium titanium, our devices are both lightweight and highly durable.",
    },
    {
      title: "Discreet sensors ",
      description:
        "Non-allergenic sensors ensure maximum comfort while monitoring your health seamlessly",
    },
    {
      title: "Non-allergenic",
      description:
        "Maximizes energy efficiency, ensuring long-lasting performance with every charge.",
    },

  ];
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // Function to open the popup
  const openPopup = () => {
    setIsPopupOpen(true);
  };

  // Function to close the popup
  const closePopup = () => {
    setIsPopupOpen(false);
  };


  const [isSection1Visible, setIsSection1Visible] = React.useState(false);
  const [isSection2Visible, setIsSection2Visible] = React.useState(false);
  const section1Ref = React.useRef(null);
  const section2Ref = React.useRef(null);

  React.useEffect(() => {
    const observer1 = new IntersectionObserver(
      ([entry]) => {
        setIsSection1Visible(entry.isIntersecting);
      },
      { threshold: 0.5 } // Trigger when 50% of section is visible
    );

    const observer2 = new IntersectionObserver(
      ([entry]) => {
        setIsSection2Visible(entry.isIntersecting);
      },
      { threshold: 0.8 } // Trigger when 18% of section is visible
    );

    if (section1Ref.current) {
      observer1.observe(section1Ref.current);
    }

    if (section2Ref.current) {
      observer2.observe(section2Ref.current);
    }

    return () => {
      observer1.disconnect();
      observer2.disconnect();
    };
  }, []);

  return (
    <>
      <div className="relative">
        {/* Container for sticky behavior */}
        <div className="relative">
          {/* Section 1 - Battery Life (Sticky) */}
          <section
            ref={section1Ref}
            className="sticky top-0 z-10 flex min-h-[100vh] items-center justify-between bg-[#F5F5F7] px-6 py-12 lg:px-16"
          >
            {/* Background Video for Section 1 */}
            <video
              src={Vedio}
              autoPlay
              loop
              muted
              className="absolute inset-0 h-full w-full object-cover z-0"
            />
            <div
              className={` flex flex-col gap-5 max-w-2xl relative z-10 space-y-4 transition-all duration-500 ${
                isSection2Visible
                  ? "opacity-20 blur-md translate-y-5"
                  : isSection1Visible
                  ? "opacity-100 blur-none -translate-y-10"
                  : "opacity-0 blur-md translate-y-5"
              }`}
            >
              <h2 className="text-[56px] font-[400] leading-[61.6px] font-akkurat tracking-tight  lg:text-[56px]">
                All-in-One{" "}
                <span className="font-editorial italic"> Safety Watch </span>{" "}
              </h2>
              <div>
              <button
                  className="flex items-center py-3 px-8 bg-gray-400 text-white rounded-full"
                  onClick={openPopup}
                >
                  <IoPlayCircleOutline size={30} className="mr-2 " />{" "}
                  {/* Add margin-right for spacing */}
                  See How it's Made
                </button>
                </div>

                {/* Left side tabs with default gray border */}
                <div className="flex flex-col space-y-6 w-1/2 border-gray-600 pr-4">
                  {tabs1.map((tab, index) => (
                    <div key={index} className="flex flex-col">
                      <button
                        className={` text-left text-[24px] font-[400] leading-[32px] border-l-2 ${
                          activeTab === index
                            ? "text-black border-black"
                            : "text-[#908A82] border-[##908A82]"
                        } pl-3`}
                        onClick={() => setActiveTab(index)}
                      >
                        {tab.title}
                      </button>
                      {/* Conditionally render the animated description under the title when the tab is active */}
                      {activeTab === index && (
                        <motion.div
                          initial={{ opacity: 0, y: -10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          transition={{ duration: 0.3 }}
                          className="text-sm font-[400] leading-[20px] text-[#908A82] pl-3 border-l-2 border-[#908A82]"
                        >
                          {tab.description}
                        </motion.div>
                      )}
                    </div>
                  ))}
                </div>
            
            </div>
          </section>

          {/* Section 2 - Health (Overlapping) */}
          <section
            ref={section2Ref}
            className={`relative py-5 z-20 desktop:min-h-[100vh] h-[70%] bg-[#F5F5F7]/95 backdrop-blur-sm transition-opacity duration-200 ${
              isSection2Visible ? "opacity-100" : "opacity-0"
            }`}
          >
            {/* Background Video for Section 2 */}
            <video
              src={Vedio1} // Use the same video or a different one for this section
              autoPlay
              loop
              muted
              className="absolute inset-0 h-full w-full object-cover z-0"
            />
            <div
              className={`flex min-h-[75vh] items-center justify-between px-6 lg:px-16 transition-all duration-300 ${
                isSection2Visible
                  ? "opacity-100 blur-none translate-y-0"
                  : "opacity-0 blur-sm translate-y-5"
              }`}
            >
              <div
                className={` flex flex-col gap-5 max-w-2xl  space-y- transition-all duration-500 ${
                  isSection2Visible
                    ? "opacity-100 blur-none -translate-y-10"
                    : "opacity-20 blur-md translate-y-5"
                }`}
              >
                <h2 className="text-[56px] font-[400] leading-[61.6px] font-akkurat tracking-tight lg:text-[56px]">
               Incredible {" "}
                <span className="font-editorial italic"> comfort </span>{" "}
                for 24/7 wear
              </h2>
              <div>
              <button
                  className="flex items-center py-3 px-8 bg-gray-400 text-white rounded-full"
                  onClick={openPopup}
                >
                  <IoPlayCircleOutline size={30} className="mr-2 " />{" "}
                  {/* Add margin-right for spacing */}
                  See How it's Made
                </button>
                </div>

                {/* Left side tabs with default gray border */}
                <div className="flex flex-col space-y-6 w-1/2 border-gray-600 pr-4">
                  {tabs2.map((tab, index) => (
                    <div key={index} className="flex flex-col">
                      <button
                        className={` text-left text-[24px] font-[400] leading-[32px] border-l-2 ${
                          activeTab === index
                            ? "text-black border-black"
                            : "text-[#908A82] border-[##908A82]"
                        } pl-3`}
                        onClick={() => setActiveTab(index)}
                      >
                        {tab.title}
                      </button>
                      {/* Conditionally render the animated description under the title when the tab is active */}
                      {activeTab === index && (
                        <motion.div
                          initial={{ opacity: 0, y: -10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          transition={{ duration: 0.3 }}
                          className="text-sm font-[400] leading-[20px] text-[#908A82] pl-3 border-l-2 border-[#908A82]"
                        >
                          {tab.description}
                        </motion.div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* Popup overlay */}
        {isPopupOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            {/* Popup content */}
            <div className="bg-white p-10 rounded-lg shadow-lg w-11/12 md:w-3/4 lg:w-1/2 relative">
              {/* Close button */}
              <button
                className="absolute top-3 right-3 text-gray-700 hover:text-gray-900"
                onClick={closePopup}
              >
                ✕
              </button>

              {/* Video player */}
              <ReactPlayer
                url={Vedio} // Replace with your video URL
                controls
                playing
                width="100%"
                height="100%"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
