import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCart } from "../../context/AppContext";
import logo from '../../images/logo/PNG.png';
import { MdCheckCircle } from 'react-icons/md';

export default function Sos() {
  const { addToCart } = useCart();
  const navigate = useNavigate();
  const [selectedView, setSelectedView] = useState("images");
  const [selectedTab, setSelectedTab] = useState("feature");
  const [isFading, setIsFading] = useState(false);

  const product = {
    id: 2,
    name: "Ringer-push Button",
    price: 449,
    image: "../watch/mgmini-lite-01.webp",
    finish: "Silver",
    images: [
      "../watch/mgmini-lite-01.webp",
      "../pendant/vip-active-main-3a.webp",
      "../pendant/img_7038-edit.webp",
    ],
    description: "This high-quality ringer watch is meticulously designed for active lifestyles, blending durability with a sleek, modern style. It features a robust build, water resistance, and a comfortable fit, making it ideal for daily wear or outdoor adventures. The watch's advanced features and elegant design make it perfect for those who value both functionality and fashion.",
    feature: [
      "Smart, Sleek design with Smarti Voice Assist Button",
      "1,400 ft (about 4 football fields,) of protection",
      "Can also sync with included necklace or wristband",
      "Includes access to the MyGuardian Portal & App - a powerful tool that enables caregiver connection",
    ],
    specification: {
      Height: "2.3 Inches",
      Width: "6.4 Inches",
      Depth: "6.4 Inches",
      Weight: "1.27 lbs",
      Power: "Wall outlet, Lithium Ion Back up Battery",
    },
  };

  const [selectedImage, setSelectedImage] = useState(product.images[0]);

  const handleBoxClick = (view) => {
    setSelectedView(view);
    if (view === "images") {
      setSelectedImage(product.images[0]);
    } else if (view === "description") {
      setSelectedImage(product.descriptionImage);
    } else if (view === "specifications") {
      setSelectedImage(product.specificationsImage);
    }
  };

  const handleTabClick = (tab) => {
    setIsFading(true);
    setTimeout(() => {
      setSelectedTab(tab);
      setIsFading(false);
    }, 300); // delay in milliseconds
  };

  const goToCheckout = () => {
    addToCart(product);
    navigate("/checkout");
  };

  return (
    <>
      <main className="mx-auto max-w-[1920px] w-full bg-[#FEFAEF]">
        <div className="grid lg:grid-cols-12">
          <div className="lg:col-span-8 w-full relative">
            <header className="border-b px-5">
              <div className="absolute top-10 w-[93%] flex items-center justify-between">
                <Link to="/" className="w-[150px] h-[50px] text-3xl font-[400] leading-24">
                  <img src={logo} alt="Logo" className="w-full h-full" />
                </Link>
              </div>
            </header>

            {/* Main Slider Area */}
            <div className="aspect-square h-[300px] md:h-screen w-full overflow-hidden border bg-white">
              <img
                src={selectedImage}
                alt="Product"
                className="h-full w-full object-cover"
              />
            </div>

            {/* Thumbnails for Product Images */}
            {selectedView === "images" && (
              <div className="absolute bottom-4 md:bottom-16 left-1/2 transform -translate-x-1/2 flex justify-center gap-4">
                {product.images.map((thumb, index) => (
                  <button
                    key={index}
                    onClick={() => setSelectedImage(thumb)}
                    className="overflow-hidden rounded-full border bg-white hover:border-[#EE344E] "
                  >
                    <img
                      src={thumb}
                      alt={`Thumbnail ${index + 1}`}
                      className="h-12 w-16 md:h-16 md:w-full object-cover"
                    />
                  </button>
                ))}
              </div>
            )}
          </div>

          {/* Right-side Box (Product Details) */}
          <div className="lg:col-span-4 w-full space-y-6 bg-[#FEFAEF] px-3">
            <div className="space-y-2">
              <h2 className="text-3xl font-[500] text-center text-[#EE344E] py-3">ON THE GO</h2>
              <h2 className="text-3xl font-[400] text-center pb-3">Ringer Watch</h2>

              {/* Product Image Box */}
              <div
                className={`cursor-pointer pr-2 border rounded-lg hover:bg-white ${selectedView === "images" ? "border-[#EE344E] bg-white" : ""}`}
                onClick={() => handleBoxClick("images")}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <img src={product.image} alt={product.name} className="w-24 h-20 rounded-l-lg" />
                    <span className="font-medium">{product.name}</span>
                  </div>
                  <span className="font-semibold">${product.price}</span>
                </div>
              </div>

              {/* Product Description Box */}
              <div className="border p-6 rounded-lg font-akkurat">
                <h3 className="font-medium text-lg">Description</h3>
                <p className="text-[16px] font-[400] leading-[26px] text-gray-700">{product.description}</p>
              </div>
            </div>

            {/* Total Price and Add to Cart */}
            <div className="flex items-center justify-between border-t pt-6 pb-10 md:mb-0">
              <div className="text-2xl font-bold">${product.price} USD</div>
              <button
                onClick={goToCheckout}
                className="px-6 py-3 text-xl bg-[#EE344E] text-white rounded-3xl hover:bg-[#fc0829] mb-5 md:mb-0"
              >
                Add to Cart
              </button>
            </div>
          </div>
        </div>

        <div className="mt-6 md:w-[63.3%] w-[100%] md:ml-10 font-akkurat">
          <div className="flex gap-20 border-b border-[#EE344E] cursor-pointer text-xl">
            <div
              className={`px-6 py-2 border-b-2 ${selectedTab === "feature" ? "border-[#EE344E] text-[#EE344E] font-semibold" : "border-transparent text-[#EE344E]"}`}
              onClick={() => handleTabClick("feature")}
            >
              Feature
            </div>
            <div
              className={`px-6 py-2 border-b-2 ${selectedTab === "specification" ? "border-[#EE344E] text-[#EE344E] font-semibold" : "border-transparent text-[#EE344E]"}`}
              onClick={() => handleTabClick("specification")}
            >
              Specification
            </div>
          </div>

          {/* Tab Content with Smooth Fade Animation */}
          <div className={`mt-4 p-4 rounded-lg transition-opacity duration-500 ${isFading ? "opacity-0" : "opacity-100"}`}>
            {selectedTab === "feature" && (
              <ul className="list-none  space-y-2 text-sm text-gray-700 pb-5">
                {product.feature.map((feature, index) => (
                  <li key={index} className="flex items-center gap-2 text-[20px] font-[400] leading-[26px]">
                    <MdCheckCircle className="text-[#EE344E]" />
                    {feature}
                  </li>
                ))}
              </ul>
            )}
            {selectedTab === "specification" && (
              <div className="overflow-x-auto pb-5">
                <table className="w-full text-sm text-gray-700">
                  <tbody>
                    {Object.entries(product.specification).map(([key, value], index) => (
                      <tr key={index} className="text-[20px] font-[400] leading-[22px] border-b border-gray-200">
                        <td className="py-2 font-semibold pr-4">{key}:</td>
                        <td className="py-2">{value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

          </div>
        </div>
      </main>
    </>
  );
}
