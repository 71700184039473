"use client";
import { useEffect, useState } from "react";

import * as React from "react";
import {
  FaMoon,
  FaRunning,
  FaBolt,
  FaChartLine,
  FaHeart,
  FaVenus,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";

const cn = (...classes) => classes.filter(Boolean).join(" ");

const tabs = [
  {
    id: "sleep",
    label: "Sleep",
    icon: FaMoon,
    slides: [
      {
        id: "overview",
        image: "./tab-images/sportsman-checking-his-watch-before-starting-run.webp",
      },
      {
        id: "sleep-score",
        title: "Sleep Score",
        score: 91,
        status: "Optimal",
        description:
          "Your score is based on your total sleep, heart rate variability (HRV), nighttime movement, sleep regularity, and more.",
      },
      {
        id: "sleep-stages",
        title: "Sleep Stages",
        time: "7h 43min",
        description:
          "Wake up to in-depth analysis of your deep sleep, REM sleep, and light sleep from the night prior.",
        stages: [
          { label: "Awake", duration: "1h 21m", percentage: "15%" },
          { label: "REM", duration: "1h 45m", percentage: "25%" },
          { label: "Light", duration: "4h 4m", percentage: "53%" },
          { label: "Deep", duration: "1h 2m", percentage: "12%" },
        ],
      },
      {
        id: "blood-oxygen",
        title: "Blood Oxygen Sensing",
        percentage: "98%",
        status: "Optimal",
        description:
          "By detecting your blood oxygen levels at night, Medringer can tell if you're experiencing any breathing disturbances.",
      },
      {
        id: "bedtime",
        title: "Bedtime Guidance",
        time: "10:30 PM",
        status: "Recommended",
        description:
          "Get personalized bedtime recommendations based on your sleep patterns and daily activity.",
      },
    ],
  },
  {
    id: "activity",
    label: "Activity and Fitness",
    icon: FaRunning,
    slides: [
      {
        id: "overview",
        image: "./tab-images/digital-wrist-watch.webp",
      },
      {
        id: "daily-movement",
        title: "Daily Movement",
        score: 85,
        status: "Good",
        description: "Track your daily movement, exercise, and standing time.",
      },
      {
        id: "workout-intensity",
        title: "Workout Intensity",
        percentage: "75%",
        status: "High",
        description: "Monitor your workout intensity for better results.",
      },
      {
        id: "recovery-time",
        title: "Recovery Time",
        time: "14h",
        status: "Recommended",
        description: "Get personalized recovery recommendations.",
      },
      {
        id: "weekly-goals",
        title: "Weekly Goals",
        percentage: "80%",
        status: "On Track",
        description: "Track your progress towards your weekly fitness goals.",
      },
    ],
  },
  {
    id: "readiness",
    label: "Readiness",
    icon: FaBolt,
    slides: [
      {
        id: "overview",
        image: "./tab-images/side-view-woman-holding-walkie-talkie.webp",
      },
      {
        id: "readiness-score",
        title: "Readiness Score",
        score: 88,
        status: "Optimal",
        description:
          "How prepared your body is for activity based on key metrics.",
      },
      {
        id: "recovery-index",
        title: "Recovery Index",
        percentage: "92%",
        status: "Excellent",
        description: "Track recovery from daily stress and activity.",
      },
      {
        id: "hrv-balance",
        title: "HRV Balance",
        status: "Balanced",
        description: "Monitor autonomic nervous system through HRV.",
      },
      {
        id: "body-temperature",
        title: "Body Temperature",
        status: "Normal",
        description: "Track your body temperature variations.",
      },
    ],
  },
  {
    id: "stress",
    label: "Stress",
    icon: FaChartLine,
    slides: [
      {
        id: "overview",
        image: "./tab-images/high-angle-man-wearing-watch.webp",
      },
      {
        id: "stress-level",
        title: "Stress Level",
        score: 32,
        status: "Low",
        description: "Monitor stress levels and identify patterns.",
      },
      {
        id: "meditation-minutes",
        title: "Meditation",
        time: "20min",
        status: "Goal Met",
        description: "Track mindfulness and meditation sessions.",
      },
      {
        id: "restorative-time",
        title: "Restorative Time",
        percentage: "65%",
        status: "Good",
        description: "Monitor low stress and high recovery periods.",
      },
      {
        id: "stress-management",
        title: "Stress Management",
        status: "Effective",
        description: "Learn techniques to manage and reduce stress.",
      },
    ],
  },
  {
    id: "heart-health",
    label: "Heart Health",
    icon: FaHeart,
    slides: [
      {
        id: "overview",
        image: "./tab-images/close-up-woman-with-watch.webp",
      },
      {
        id: "heart-rate",
        title: "Heart Rate",
        score: 72,
        status: "Normal",
        description: "Track heart rate patterns day and night.",
      },
      {
        id: "hrv-tracking",
        title: "HRV Tracking",
        score: 45,
        status: "Good",
        description: "Monitor heart rate variability for health insights.",
      },
      {
        id: "afib-detection",
        title: "AFib Detection",
        status: "Normal",
        description: "Continuous monitoring for irregular heart rhythms.",
      },
      {
        id: "blood-pressure",
        title: "Blood Pressure",
        status: "Normal",
        description: "Keep track of your blood pressure readings.",
      },
    ],
  },
  {
    id: "womens-health",
    label: "Women's Health",
    icon: FaVenus,
    slides: [
      {
        id: "overview",
        image: "./tab-images/male-hand-clenched-into-fist-with-fitness-bracelet-it-multicolored-background.webp",
      },
      {
        id: "cycle-tracking",
        title: "Cycle Tracking",
        status: "Follicular Phase",
        description: "Track menstrual cycle and predict upcoming phases.",
      },
      {
        id: "fertility-window",
        title: "Fertility Window",
        status: "Low Fertility",
        description: "Monitor fertile window and ovulation predictions.",
      },
      {
        id: "symptom-tracking",
        title: "Symptom Tracking",
        status: "Updated",
        description: "Log and track cycle-related symptoms and patterns.",
      },
      {
        id: "hormonal-insights",
        title: "Hormonal Insights",
        status: "Stable",
        description: "Understand how hormones affect your body and mood.",
      },
    ],
  },
];

export default function Tabs() {
  const [activeTab, setActiveTab] = useState("sleep");
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slidesPerView, setSlidesPerView] = useState(4); // Default to 4 slides on larger screens

  const activeTabData = tabs.find((tab) => tab.id === activeTab);
  const slides = activeTabData?.slides || [];

  useEffect(() => {
    const updateSlidesPerView = () => {
      if (window.innerWidth < 640) {
        setSlidesPerView(1); // Mobile screens show 1 slide
      } else if (window.innerWidth < 1024) {
        setSlidesPerView(2); // Medium screens show 2 slides
      } else {
        setSlidesPerView(4); // Large screens show 4 slides
      }
    };

    window.addEventListener("resize", updateSlidesPerView);
    updateSlidesPerView(); // Initial check
    return () => window.removeEventListener("resize", updateSlidesPerView);
  }, []);

  const totalSlides = Math.ceil(slides.length / slidesPerView); // Total number of slide sets
  const maxSlideIndex = totalSlides - 1;

  const nextSlide = () => {
    if (currentSlide < maxSlideIndex) {
      setCurrentSlide((prev) => prev + 1);
    }
  };

  const previousSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prev) => prev - 1);
    }
  };

  return (
    <>
    <div className="max-w-[1440px] w-full mx-auto px-4 py-8 bg-[#F5F5F7]">
      <div className="w-full overflow-x-auto no-scrollbar">
        <div className="flex gap-16 pb-4 mb-8 min-w-min px-4 md:px-12">
          {tabs.map((tab) => {
            const Icon = tab.icon;
            return (
              <button
                key={tab.id}
                onClick={() => {
                  setActiveTab(tab.id);
                  setCurrentSlide(0);
                }}
                className={cn(
                  "flex flex-col items-center gap-4 min-w-[100px] pb-2 border-b-2 transition-colors",
                  activeTab === tab.id
                    ? "border-gray-900 text-gray-900"
                    : "border-transparent text-gray-500 hover:text-gray-900"
                )}
              >
                <Icon className="h-5 w-5" />
                <span className="text-sm font-medium whitespace-nowrap">
                  {tab.label}
                </span>
              </button>
            );
          })}
        </div>
      </div>

      <div className="relative">
        {currentSlide > 0 && (
          <button
            onClick={previousSlide}
            className="absolute left-0 top-1/2 -translate-y-1/2 z-10 p-2 rounded-full bg-white shadow-lg hover:bg-gray-50"
            aria-label="Previous slide"
          >
            <FaChevronLeft className="h-4 w-4 text-gray-600" />
          </button>
        )}
        {currentSlide < totalSlides && (
          <button
            onClick={nextSlide}
            className="absolute right-0 top-1/2 -translate-y-1/2 z-10 p-2 rounded-full bg-white shadow-lg hover:bg-gray-50"
            aria-label="Next slide"
          >
            <FaChevronRight className="h-4 w-4 text-gray-600" />
          </button>
        )}

        <div className="overflow-hidden px-4 md:px-12">
          <div
            className="flex transition-transform duration-300 ease-in-out"
            style={{
              transform: `translateX(-${(currentSlide * 100) / (slidesPerView === 4 ? 4 : slidesPerView)}%)`,
            }}
          >
            {slides.map((slide) => (
              <div
                key={slide.id}
                className="w-full sm:w-1/4 md:w-1/3 lg:w-1/4 flex-shrink-0 px-2 mb-4"
              >
                <div className="bg-[#2c3440] text-white rounded-lg p-6 h-full">
                  <div className="flex flex-col items-center text-center gap-4 h-full">
                    {slide.image && (
                      <div className="relative w-full aspect-[4/3] rounded-lg overflow-hidden mb-4">
                      <img
                        src={slide.image}
                        alt={slide.title || "Feature image"}
                        className="object-contain w-full h-full"
                      />
                    </div>
                    
                    )}
                    {slide.score !== undefined && (
                      <div className="relative w-32 h-32">
                        <div className="absolute inset-0 flex flex-col items-center justify-center">
                          <div className="text-4xl font-bold">
                            {slide.score}
                          </div>
                          {slide.status && (
                            <div className="text-xs text-blue-400 mt-1">
                              {slide.status}
                            </div>
                          )}
                        </div>
                        <svg
                          className="w-full h-full -rotate-90"
                          viewBox="0 0 100 100"
                        >
                          <circle
                            cx="50"
                            cy="50"
                            r="45"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            className="text-gray-700"
                          />
                          <circle
                            cx="50"
                            cy="50"
                            r="45"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeDasharray={`${(slide.score / 100) * 283} 283`}
                            className="text-blue-400"
                          />
                        </svg>
                      </div>
                    )}
                    {slide.stages && (
                      <div className="w-full space-y-2">
                        <div className="text-2xl font-bold">{slide.time}</div>
                        <div className="space-y-1 text-xs">
                          {slide.stages.map((stage) => (
                            <div
                              key={stage.label}
                              className="flex items-center justify-between"
                            >
                              <span className="text-gray-400">
                                {stage.label}
                              </span>
                              <span>{stage.duration}</span>
                              <span className="text-gray-400">
                                {stage.percentage}
                              </span>
                            </div>
                          ))}
                        </div>
                        <div className="h-2 bg-gray-700 rounded-full overflow-hidden">
                          <div
                            className="h-full bg-gradient-to-r from-blue-400 to-blue-600"
                            style={{ width: "53%" }}
                          />
                        </div>
                      </div>
                    )}
                    {slide.percentage && !slide.stages && (
                      <div className="space-y-2">
                        <div className="text-3xl font-bold">
                          {slide.percentage}
                        </div>
                        {slide.status && (
                          <div className="text-sm text-blue-400 font-medium">
                            {slide.status}
                          </div>
                        )}
                      </div>
                    )}
                    {slide.time && !slide.stages && (
                      <div className="space-y-2">
                        <div className="text-3xl font-bold">{slide.time}</div>
                        {slide.status && (
                          <div className="text-sm text-blue-400 font-medium">
                            {slide.status}
                          </div>
                        )}
                      </div>
                    )}
                    {!slide.score &&
                      !slide.stages &&
                      !slide.percentage &&
                      !slide.time &&
                      slide.status && (
                        <div className="text-xl font-bold text-blue-400 mb-2">
                          {slide.status}
                        </div>
                      )}
                    <h3 className="text-lg font-semibold">{slide.title}</h3>
                    <p className="text-gray-400 text-sm flex-grow">
                      {slide.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>

    {/* -------------------------- */}

  </>
  );
}
