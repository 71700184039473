import React, { useState } from 'react'
import Header from '../components/Header';
import banner from '../images/orignal/webp/Hafiz-1.webp'
import { FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
import axios from 'axios';

export default function FreeAdvise() {

    const [status, setStatus] = useState('');
    const [formData, setFormData] = useState({
        fname: '',
        lname: '',
        email: '',
        phone: '',
        message: '',

    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
          const response = await axios.post('http://localhost:5000/api/send-email', formData);
          setStatus('Message sent successfully!');
        } catch (error) {
          setStatus('Error sending message');
        }
      };
    


    return (
        <>
            <Header
                backgroundImage={banner}
                bannerHeading="Get Expert Advice"
                bannerText="Ask our specialists anything—free advice to guide your decisions"

            />
            <div className="max-w-7xl md:w-[80%] w-[100%] mx-auto p-6 ont-akkurat">
                {/* Top Form Section */}
                <div className="w-full md:p-6 p-0 space-y-6">
                    <h2 className="text-3xl font-bold tracking-tight">
                        LEAVE A <span className="text-[#fc0829]">MESSAGE</span>
                    </h2>
                    <form
                        onSubmit={handleSubmit}
                        className="space-y-6">
                        <div className="grid gap-4 sm:grid-cols-2">
                            <input
                                placeholder="First Name*"
                                name='fname'
                                value={formData.fname}
                                onChange={handleChange}
                                required
                                type="text"
                                className="border border-slate-500 px-2 py-2 bg-gray-100 focus:bg-gray-100 focus:outline-none focus:border-[#fc0829]"
                            />
                            <input
                                placeholder="Last Name*"
                                name='lname'
                                value={formData.lname}
                                onChange={handleChange}
                                required
                                type="text"
                                className="border border-slate-500 px-2 py-2 bg-gray-100 focus:bg-gray-100 focus:outline-none focus:border-[#fc0829]"
                            />
                        </div>

                        <div className="grid gap-4 sm:grid-cols-2">
                            <input
                                placeholder="Email*"
                                name='email'
                                value={formData.email}
                                onChange={handleChange}
                                required
                                type="email"
                                className="border border-slate-500 px-2 py-2 bg-gray-100 focus:bg-gray-100 focus:outline-none focus:border-[#fc0829]"
                            />
                            <input
                                placeholder="Phone*"
                                name='phone'
                                value={formData.phone}
                                onChange={handleChange}
                                required
                                type="tel"
                                className="border border-slate-500 px-2 py-2 bg-gray-100 focus:bg-gray-100 focus:outline-none focus:border-[#fc0829]"
                            />
                        </div>

                        <div className="grid">
                            <textarea
                                placeholder="Message"
                                name='message'
                                value={formData.message}
                                onChange={handleChange}
                                className="min-h-[150px] border border-slate-500 px-2 py-2 bg-gray-100 focus:bg-gray-100 focus:outline-none focus:border-[#fc0829]"
                            />
                        </div>

                        <div className="space-y-4">
                            <div className="g-recaptcha" data-sitekey="your-recaptcha-site-key"></div>
                            <button type="submit" className="px-10 py-2 rounded-full text-white bg-[#EE344E] hover:bg-[#fc0829]">
                                Send Message
                            </button>
                        </div>
                    </form>
                </div>
                {/* Contact Information Section */}
                <div className="flex md:flex-row flex-col mb-12 gap-6">
                    {/* Email Box */}
                    <div className="bg-gray-50 border-l-4 border-[#fc0829] p-6 rounded-lg shadow-md flex flex-col items-start w-full md:w-1/3 h-full">
                        <div className="flex items-center mb-2">
                            <FaEnvelope className="text-[#fc0829] mr-2" />
                            <h3 className="text-xl font-semibold text-[#fc0829]">Email</h3>
                        </div>
                        <a href="mailto:info@medringer.com" className="text-gray-700 hover:text-[#fc0829] transition duration-200">
                            Info@medringer.com
                        </a>
                    </div>

                    {/* Phone Box */}
                    <div className="bg-gray-50 border-l-4 border-[#fc0829] p-6 rounded-lg shadow-md flex flex-col items-start w-full md:w-1/3 h-full">
                        <div className="flex items-center mb-2">
                            <FaPhone className="text-[#fc0829] mr-2" />
                            <h3 className="text-xl font-semibold text-[#fc0829]">Phone</h3>
                        </div>
                        <a href="tel:18443444666" className="text-gray-700 hover:text-[#fc0829] transition duration-200">
                        +1-844-344-4666
                        </a>
                    </div>

                    {/* Address Box */}
                    <div className="bg-gray-50 border-l-4 border-[#fc0829] p-6 rounded-lg shadow-md flex flex-col items-start w-full md:w-1/3 h-full">
                        <div className="flex items-center mb-2">
                            <FaMapMarkerAlt className="text-[#fc0829] mr-2" />
                            <h3 className="text-xl font-semibold text-[#fc0829]">Address</h3>
                        </div>
                        <p className="text-gray-700">
                           Virginia, Dale City, USA
                        </p>
                    </div>
                </div>


            </div>
        </>
    );
};
